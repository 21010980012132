import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import dynamic from 'next/dynamic';
import Link from 'next/link';

import CloudinaryImage from '../common/CloudinaryImage';

import Container from './Container';
const Slider = dynamic(() => import('react-slick'));
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../common/ourclient.css';

export default function OurClient({ ClientSucess, isColor }) {
  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          adaptiveHeight: true,
        },
      },
    ],
  };

  return (
    <div className={`lg:py-[75px] py-[45px] clientsucess bg-[#00060F]`}>
      <Container
        className={
          ClientSucess?.titleEditor && 'xl:mb-[34px] mb-[30px] text-[#fff]'
        }
      >
        {ClientSucess?.titleEditor && (
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            className={`ourClient  font-[400] xl:text-[55px] md:text-[32px] text-[26px] title_hd leading-tight`}
            source={ClientSucess?.titleEditor}
          >
            {ClientSucess?.titleEditor}
          </ReactMarkdown>
        )}
      </Container>
      <Container className="mr-0 pr-0 pl-0 overflow-hidden">
        <Slider {...settings}>
          {ClientSucess?.Clients.map(client => (
            <Link
              key={client?.id}
              href={`${client?.link ? client?.link : ''}`}
              className="block text-center border border-[#4A4A4A] bg-[#21212199] py-4 px-1 rounded-[8px]"
            >
              {client?.image?.data?.attributes?.url && (
                <div className="w-[100px] h-[130px] flex items-center justify-center mx-auto">
                  <CloudinaryImage
                    backendImgUrl={client?.image?.data?.attributes?.url}
                    alt={client?.image?.data?.attributes?.alternativeText}
                    type="smallimg"
                    className={`w-full h-auto max-h-full`}
                  />
                </div>
              )}
              {client?.text1 && (
                <div
                  className={`${client?.text1 && 'mt-5'} font-[600] text-[#FFFFFF] text-[19px]`}
                >
                  {client?.text1}
                </div>
              )}
              {client?.text2 && (
                <div className="font-[600] text-[#FFFFFF] text-[16px]">
                  {client?.text2}
                </div>
              )}
            </Link>
          ))}
        </Slider>
      </Container>
    </div>
  );
}
